define("admin/models/shot", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    instructions: _emberData.default.attr('string'),
    pilot_comment: _emberData.default.attr('string'),
    origin_type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    image_archive_url: _emberData.default.attr('string'),
    post_processing_status: _emberData.default.attr('string'),
    camera_requirement: _emberData.default.attr('string'),
    camera_sensor: _emberData.default.attr('string'),
    video: _emberData.default.attr('string'),
    min: _emberData.default.attr('number'),
    max: _emberData.default.attr('number'),
    asset_type: _emberData.default.attr('string'),
    template: _emberData.default.attr('string'),
    area_of_interest: _emberData.default.attr('string'),
    level_of_detail: _emberData.default.attr('string'),
    gimbal_angle: _emberData.default.attr('number'),
    front_overlap: _emberData.default.attr('number'),
    side_overlap: _emberData.default.attr('number'),
    pattern: _emberData.default.attr('string'),
    altitude: _emberData.default.attr('number'),
    time_of_day: _emberData.default.attr('string'),
    file_type: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission', {
      async: false
    }),
    shot_type: _emberData.default.belongsTo('shot_type', {
      async: false
    }),
    shot_list: _emberData.default.belongsTo('shot_list', {
      async: false
    }),
    data_files: _emberData.default.hasMany('data_files', {
      async: true
    }),
    images: _emberData.default.hasMany('images', {
      async: true
    }),
    image_markers: _emberData.default.hasMany('image_markers', {
      async: true
    }),
    videos: _emberData.default.hasMany('videos', {
      async: false
    }),
    panoramas: _emberData.default.hasMany('panoramas', {
      async: false
    }),
    ortho_photo_maps: _emberData.default.hasMany('ortho_photo_maps', {
      async: false
    }),
    activity_logs: _emberData.default.hasMany('activity_logs', {
      async: false
    }),
    post_processes: _emberData.default.hasMany('post-process'),
    imagePagingMeta: Object.freeze({
      total_count: 0
    }),
    totalAssetCount: Ember.computed('images.[]', 'videos.[]', 'panoramas.[]', 'ortho_photo_maps.[]', function () {
      return this.images.length + this.videos.length + this.panoramas.length + this.ortho_photo_maps.length;
    }),
    imageCount: Ember.computed('imagePagingMeta', function () {
      return this.imagePagingMeta.total_count;
    }),
    imagesPerPage: Ember.computed('imagePagingMeta', function () {
      return this.imagePagingMeta.per_page;
    }),
    hasMissingGps: Ember.computed('imagePagingMeta.missing_gps_info_count', function () {
      return this.imagePagingMeta.missing_gps_info_count > 0;
    }),
    hasMissingRtk: Ember.computed('imagePagingMeta.missing_rtk_data_count', function () {
      return this.imagePagingMeta.missing_rtk_data_count > 0;
    }),
    timeSortedImages: Ember.computed('images.[]', function () {
      return this.get('images').toArray().sort((a, b) => a.takenAt.toDate() - b.takenAt.toDate());
    }),
    hasLowResolution: Ember.computed('imagePagingMeta.low_resolution_count', function () {
      return this.imagePagingMeta.low_resolution_count > 0;
    }),
    hasPanos: Ember.computed('panoramas.[]', function () {
      return this.get('panoramas.length') > 0;
    }),
    hasOrthos: Ember.computed('ortho_photo_maps.[]', function () {
      return this.get('ortho_photo_maps.length') > 0;
    }),
    loadImages() {
      if (this.images.length === this.imageCount) {
        return;
      } else if (this.imageCount - this.images.length > 500) {
        this.loadImagesPage(this.imagePagingMeta.page + 1, this.imagePagingMeta.per_page);
      } else {
        this.loadAllImages();
      }
    },
    loadAllImages() {
      if (this.images.length < this.imageCount) {
        // assumes we have already loaded the first page of images
        const imagesPerPage = this.imagePagingMeta.per_page;
        for (var i = this.imagePagingMeta.page; i <= this.imagePagingMeta.total_pages; ++i) {
          if (i != this.imagePagingMeta.page) {
            this.loadImagesPage(i, imagesPerPage);
          }
        }
      }
    },
    loadImagesPage(page, perPage) {
      return this.get('store').query('image', {
        shotId: this.get('id'),
        missionId: this.get('mission.id'),
        perPage,
        page
      }).then(response => {
        this.set('imagePagingMeta', response.meta);
      }, error => {
        if (error.errors) {
          const message = error.errors[0].detail;
          return alert(message);
        }
        // console.log 'error retrieving images: ' + message
      });
    },

    downloadImages() {
      let adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.downloadImages(this);
    },
    promoteAssets() {
      let adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.promoteAssets(this);
    },
    unpromoteAssets() {
      let adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.unpromoteAssets(this);
    },
    hasPostProcess: Ember.computed('post_processes', 'mission.{deliverable_work_types,auto_ortho_stitching,client_upload}', function () {
      const clientUpload = this.get('mission.client_upload');
      const panoramaStitching = Ember.isPresent(this.get('post_processes').findBy('slug', 'panorama_stitching'));
      const rgbOrthoStitching = Ember.isPresent(this.get('post_processes').findBy('slug', 'rgb_ortho_stitching'));
      const thermalOrthoStitching = Ember.isPresent(this.get('post_processes').findBy('slug', 'thermal_ortho_stitching'));
      const roofMeasurements = Ember.isPresent(this.get('post_processes').findBy('slug', 'roof_measurements'));
      const missionOrthoStitching = this.get('mission.auto_ortho_stitching');
      const missionRoofMeasurements = this.get('mission.deliverable_work_types') && Ember.isPresent(this.get('mission.deliverable_work_types').findBy('name', 'Roof Measurements'));
      return clientUpload || panoramaStitching || (rgbOrthoStitching || thermalOrthoStitching) && missionOrthoStitching || roofMeasurements && missionRoofMeasurements;
    }),
    processingFailed: Ember.computed('post_processing_status', function () {
      return this.get('post_processing_status') == 'error';
    }),
    isProcessing: Ember.computed.equal('post_processing_status', 'processing'),
    verboseProcessStatus: Ember.computed('post_processing_status', function () {
      switch (this.get('post_processing_status')) {
        case 'error':
          return "Processing Error";
        case 'processing':
          return "Processing...";
        default:
          return this.get('post_processing_status');
      }
    }),
    errorDataFilesCount: Ember.computed('data_files.length', function () {
      return this.get('data_files').filter(item => item.get('processing_status') == 'error').length;
    }),
    readyDataFilesCount: Ember.computed('data_files.length', function () {
      return this.get('data_files').filter(item => item.get('processing_status') == 'ready').length;
    }),
    readyImagesCount: Ember.computed('imagePagingMeta', 'imagePagingMeta.ready_count', function () {
      return this.get('imagePagingMeta.ready_count');
    }),
    errorImagesCount: Ember.computed('images.length', function () {
      return this.get('images').filter(item => item.get('processing_status') == 'error').length;
    }),
    readyVideosCount: Ember.computed('videos.length', function () {
      return this.get('videos').filter(item => item.get('processing_status') == 'ready').length;
    }),
    errorVideosCount: Ember.computed('videos.length', function () {
      return this.get('videos').filter(item => item.get('processing_status') == 'error').length;
    }),
    readyPanosCount: Ember.computed('panoramas.length', function () {
      return this.get('panoramas').filter(item => item.get('processing_status') == 'ready').length;
    }),
    errorPanosCount: Ember.computed('panoramas.length', function () {
      return this.get('panoramas').filter(item => item.get('processing_status') == 'error').length;
    }),
    readyOrthosCount: Ember.computed('ortho_photo_maps.length', function () {
      return this.get('ortho_photo_maps').filter(item => item.get('processing_status') == 'ready').length;
    }),
    errorOrthosCount: Ember.computed('ortho_photo_maps.length', function () {
      return this.get('ortho_photo_maps').filter(item => item.get('processing_status') == 'error').length;
    }),
    promotedImagesCount: Ember.computed('imagePagingMeta', 'imagePagingMeta.promoted_count', function () {
      return this.get('imagePagingMeta.promoted_count');
    }),
    promotedDataFilesCount: Ember.computed('data_files.{@each.final,length}', function () {
      return this.get('data_files').filter(item => item.get('processing_status') == 'ready' && item.get('final')).length;
    }),
    promotedVideosCount: Ember.computed('videos.{@each.final,length}', function () {
      return this.get('videos').filter(item => item.get('processing_status') == 'ready' && item.get('final')).length;
    }),
    promotedPanosCount: Ember.computed('panoramas.{@each.final,length}', function () {
      return this.get('panoramas').filter(item => item.get('processing_status') == 'ready' && item.get('final')).length;
    }),
    promotedOrthosCount: Ember.computed('ortho_photo_maps.{@each.final,length}', function () {
      return this.get('ortho_photo_maps').filter(item => item.get('processing_status') == 'ready' && item.get('final')).length;
    }),
    readyAssetsCount: Ember.computed('readyDataFilesCount', 'readyImagesCount', 'readyVideosCount', 'readyPanosCount', 'readyOrthosCount', function () {
      return this.get('readyDataFilesCount') + this.get('readyImagesCount') + this.get('readyVideosCount') + this.get('readyPanosCount') + this.get('readyOrthosCount');
    }),
    postProcessName: Ember.computed('post_processes', function () {
      return this.get('post_processes').map(type => type.name).join(' + ') || 'Not set';
    }),
    isPanoStitching: Ember.computed('post_processes', function () {
      return Ember.isPresent(this.get('post_processes').findBy('slug', 'panorama_stitching'));
    }),
    isOrthomosaic: Ember.computed('post_processes', function () {
      return Ember.isPresent(this.get('post_processes').findBy('slug', 'rgb_ortho_stitching')) || Ember.isPresent(this.get('post_processes').findBy('slug', 'thermal_ortho_stitching'));
    }),
    isHailDamageModel: Ember.computed('post_processes', function () {
      return Ember.isPresent(this.get('post_processes').findBy('slug', 'hail_damage_model'));
    }),
    isCommercialModel: Ember.computed('post_processes', function () {
      return Ember.isPresent(this.get('post_processes').findBy('slug', 'commercial_roofing_model'));
    }),
    isTurbineModel: Ember.computed('post_processes', function () {
      return Ember.isPresent(this.get('post_processes').findBy('slug', 'wind_turbine_blade_model'));
    })
  });
});