define("admin/models/ortho-photo-map", ["exports", "ember-data", "admin/models/shareable", "admin/config/environment"], function (_exports, _emberData, _shareable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shareable.default.extend({
    source_type: _emberData.default.attr('string'),
    processing: _emberData.default.attr('boolean'),
    mission: _emberData.default.belongsTo('mission'),
    admin_share: _emberData.default.belongsTo('share'),
    shot: _emberData.default.belongsTo('shot'),
    processing_status: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    remote_urls: _emberData.default.attr('hash'),
    tiles_link: _emberData.default.attr('string'),
    min_latitude: _emberData.default.attr('string'),
    max_latitude: _emberData.default.attr('string'),
    min_longitude: _emberData.default.attr('string'),
    max_longitude: _emberData.default.attr('string'),
    final: Ember.computed('source_type', function () {
      return this.get('source_type') == 'edit';
    }),
    accessKey: _emberData.default.attr('string'),
    collabLink: Ember.computed('admin_share', function () {
      return `${_environment.default.clients.host}/o/${this.get('admin_share.id')}`;
    }),
    downloadableTif: Ember.computed('remote_urls', function () {
      if (this.get('remote_urls')) {
        return `${this.get('remote_urls.tif')}`;
      }
    }),
    downloadableJpg: Ember.computed('remote_urls', function () {
      if (this.get('remote_urls.jpg.length')) {
        return `${this.get('remote_urls.jpg')}`;
      }
    })
  });
});